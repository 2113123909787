
//
// apexcharts.scss
//
.apex-charts {
    min-height: 10px !important;
    text {
        font-family: $font-family-base !important;
    }
    .apexcharts-canvas {
        margin: 0 auto;
    }
}

.apexcharts-tooltip-title,
.apexcharts-tooltip-text {
    font-family: $font-family-base !important;
}

.apexcharts-tooltip * {
    font-family: $font-family-base !important;
    color: $gray-600 !important;
}

.apexcharts-tooltip.apexcharts-theme-dark{
    * {
        color: $white !important;
    }
}

.apexcharts-legend-series {
    font-weight: $font-weight-medium;
}

.apexcharts-gridline {
    pointer-events: none;
    stroke: $apex-grid-color;
}

.apexcharts-legend-text {
    color: $gray-600 !important;
    font-family: $font-family-base !important;
    font-size: 13px !important;
}

.apexcharts-pie-label {
    fill: $white !important;
}

.apexcharts-title-text, .apexcharts-subtitle-text{
    fill: $gray-500;
}

.apexcharts-yaxis,
.apexcharts-xaxis {
    text {
        font-family: $font-family-base !important;
        fill: $gray-500;
    }
}

.apexcharts-yaxis-title {
    font-weight: 500;
}

// column dynamic loaded chart

#dynamicloadedchart-wrap {
    margin: 0px auto;
    max-width: 800px;
    position: relative;
}

.chart-box {
    padding-left: 0;
}

#chart-year,
#chart-quarter {
    width: 96%;
    max-width: 48%;
    box-shadow: none;
    padding-left: 0;
    padding-top: 20px;
    background: $card-bg;
    border: 1px solid $border-color;
}

#chart-year {
    float: left;
    position: relative;
    transition: 1s ease transform;
    z-index: 3;
    &.chart-quarter-activated {
        transform: translateX(0);
        transition: 1s ease transform;
    }
}

#chart-quarter {
    float: left;
    position: relative;
    z-index: -2;
    transition: 1s ease transform;
    &.active {
        transition: 1.1s ease-in-out transform;
        transform: translateX(0);
        z-index: 1;
    }
}

@media screen and (min-width: 480px) {
    #chart-year {
        transform: translateX(50%);
    }
    #chart-quarter {
        transform: translateX(-50%);
    }
}


body[data-layout-mode="dark"] {
    .apex-charts {
        text {
            fill: $gray-500;
        }
    }


    .apexcharts-gridline {
        stroke: lighten($gray-dark-200,2.5%);
    }

    .apexcharts-tooltip.apexcharts-theme-light {
        background-color: $gray-dark-200;
        border-color: lighten($gray-dark-200,2.5%);

        .apexcharts-tooltip-title {
            background-color: $gray-dark-300;
            border-color: lighten($gray-dark-300,2.5%);
        }
    }

    .apexcharts-legend-text {
        color: $gray-dark-500 !important;
    }

    .apexcharts-yaxis-texts-g.apexcharts-xaxis-inversed-texts-g,
    .apexcharts-grid-row {
        + line {
            stroke: lighten($gray-dark-300,2.5%);
        }
    }

    .apexcharts-xaxis line,
    .apexcharts-treemap-rect,
    .apexcharts-heatmap-rect,
    .apexcharts-pie-area {
        stroke: lighten($gray-dark-300,2.5%);
    }

    .apexcharts-radialbar-track.apexcharts-track {
        path {
            stroke: lighten($gray-dark-300,2.5%);
        }
    }
    .apexcharts-radar-series.apexcharts-plot-series {
        polygon,
        line {
            fill: $gray-dark-300;
            stroke: lighten($gray-dark-300,2.5%);
        }
    }
    .apexcharts-pie {
        circle {
            stroke: lighten($gray-dark-300,2.5%);
        }
    }

    #chart-year,
    #chart-quarter {
        background-color: $gray-dark-200;
        border-color: lighten($gray-dark-200, 2.5%);
    }

    .apexcharts-pie{
        line{
            stroke: $gray-dark-300;
        }
    }
}