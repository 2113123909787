// 
// _progress.scss
// 

// Progress height small
.progress-sm {
    height: 5px;
}


// Progress height medium
.progress-md {
    height: 8px;
}


// Progress height large
.progress-lg {
    height: 12px;
}

// Progress height Extra large
.progress-xl {
    height: 16px;
}

.custom-progess{
    position: relative;
    .progress-icon{
        position: absolute;
        top: -14px;
        .avatar-title{
          background: $card-bg;
        }
    }
  }
  
  // animated-progess
  
.animated-progess {
    position: relative;

    .progress-bar {
        position: relative;
        border-radius: 30px;
        animation: animate-positive 2s;
    }
}

@keyframes animate-positive {
    0% {
        width: 0;
    }
}

// custom-progress

.custom-progress{
    height: 15px;
    padding: 4px;
    border-radius: 30px;
    .progress-bar{
        position: relative;
        border-radius: 30px;
        
        &::before{
            content: "";
            position: absolute;
            width: 4px;
            height: 4px;
            background-color: $white;
            border-radius: 7px;
            right: 2px;
            top: 50%;
            transform: translateY(-50%);

        }
    }
}

body[data-layout-mode="dark"] {

    // progress
    .progress{
        background-color: $gray-dark-300;
    }
  }