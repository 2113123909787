@import "./icons.scss";
// Light Theme
@import "./bootstrap.scss";
//@import "../libs/gridjs/theme/mermaid.min.css";
@import "./app.scss";

.rdw-editor-main {
    border: 1px solid #f6f6f6;
    height: 239px;
    padding-left: 15px;
}

th.dark {
    color: #fff!important;
    background-color: #1a293c!important;
    border-color: #485463!important;
}

th.dark:hover {
    color: #fff!important;
    background-color: #2b394b!important;
    border-color: #485463!important;
}

.filter-table-header thead {    
    border-collapse: collapse;
    border-style: solid;
    border-bottom-color:#485463 !important;
}
.filter-table-header thead tr {    
    border-style: solid;
    border-bottom-color:#485463 !important;
}

.filter-table-header thead tr th {   
    border-style: none;
    color: var(--bs-accordion-active-color)!important;
    background-color: #f8f8f8ad;
}

.table-group-divider {
    border-top: calc(1px * 2) solid #485463 !important;
  }

.fp-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #f8f8f8ad;
}

.fp-container .fp-loader {
    top: 30%;
    left: 48%;
    z-index: 9999;
    position: absolute;
}


.firstRadio>span:first-child {
    // border: 1px solid #e2e5e8;
    border-top-left-radius: 0.75rem!important;
    border-bottom-left-radius: 0.75rem!important;
    border-top-right-radius: 0px!important;
    border-bottom-right-radius: 0px!important;
  }
  .inputRadio > span  {
    // border: 1px solid #e2e5e8;
    border-top-left-radius: 0rem!important;
    border-bottom-left-radius: 0rem!important;
    border-top-right-radius: 0px!important;
    border-bottom-right-radius: 0px!important;
  }
  input[type="radio"]{
  border-radius: 50%!important;
  }

  .input-radio-label{
    border-radius: 0;
    margin-left: -1px;
    margin-right: -1px;
  }