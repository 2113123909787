img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%!important;
}
.vertical-menu::before {
    width: 0px!important;
}

.pdf-container{
    height: 100%!important;
    width: 100%!important;
    position: relative;
    min-height: 768px!important;
}