// 
// File manager.scss
//

.categories-list{
    padding: 4px 0;
    li{
        a{
            display: block;
            padding: 8px 12px;
            color: $body-color;
            font-weight: $font-weight-medium;
        }

        &.active{
            a{
                color: $primary;
            }
        }

        ul{
            padding-left: 14px;
            li{
                a{
                    padding: 4px 12px;
                    color: $text-muted;
                    font-size: 13px;
                    font-weight: $font-weight-normal;
                }
            }
        }
    }
}


